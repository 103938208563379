import React, { useContext, useEffect, Suspense } from 'react';
import ReactGA from 'react-ga';
import {
  BrowserRouter,
  Redirect,
  Route,
  useHistory,
  Switch,
} from 'react-router-dom';
import axios from 'axios';
import Store from './state/store';
import rootReducer from './state/reducers';
import { useCookie } from './lib/useCookie';
import './Pods.scss';
import SignUp from './pods-pages/registration/sign-up';
import Login from './pods-pages/registration/login';
import About from './pods-pages/registration/about';
import Profile from './pods-pages/profile';
import PodsMap from './pods-pages/map';
import PodsPage from './pods-pages/pods';
// import Footer from './components/Footer';
import Feedback from './pods-pages/feedback';
import Privacy from './pods-pages/privacy';
import CookieNotice from './pods-pages/cookie-notice';
import CookieBanner from './components/CookieBanner';
import StoreContext from './state/context/store';
import { setMe } from './state/actions/me';
import { rootUrl, application } from './config';
import ForgotPassword from './pods-pages/registration/forgot-password';
import ResetPassword from './pods-pages/registration/reset-password';
import {
  Backdrop,
  Box,
  CircularProgress,
  Link,
  makeStyles,
  Paper,
} from '@material-ui/core';
// import Team from './pods-pages/team';
import AdminPortal from './pods-pages/admin-portal';
import MapView from './pods-pages/map/map';
import podImage from './assets/images/pods/whalePod.svg';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

// import React, { useContext, useEffect } from 'react';
// import StoreContext from '../../state/context/store';

// const Auth = (Component) => {
//   const [, dispatch] = useContext(StoreContext);
//   const { me } = {};

//   useEffect(() => {
//     if (me && !me.authenticated) {
//       window.location.href = '/';
//     }
//   }, [me]);

//   return <div>{me?.authenticated ? <Component /> : null}</div>;
// };

const PrivateRoute = ({ component: Component, path }) => {
  const [store] = useContext(StoreContext);
  const { me } = store;
  return (
    <Route
      path={path}
      render={(props) =>
        !me.authenticated &&
        me.authenticated !== false ? null : me.authenticated === false ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Component me={me} {...props} />
        )
      }
    />
  );
};

//if a user tried to access a route that is for an unauthenticated user, kick them to account page
const AuthRoute = ({ component: Component, path }) => {
  const [store] = useContext(StoreContext);
  const { me } = store;

  return (
    <Route
      path={path}
      render={(props) =>
        me.authenticated === true ? (
          <Redirect to={{ pathname: `/profile/${me.id}` }} />
        ) : (
          <Component me={me} {...props} />
        )
      }
    />
  );
};

const ScreenCover = () => {
  const showAnyway = localStorage.getItem('showAnyway') || false;

  return (
    <div className={`screenCover ${showAnyway ? 'showAnyway' : ''}`}>
      <Paper className="userMessageBox">
        <img src={podImage} alt="Pods" />
        <Box className="userMessageHeader">
          This website not currently optimized for mobile devices...Stay tuned
          as we are working on it!
        </Box>
        <Box className="userMessageMessage">
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              //hide screen cover now
              const parent =
                e.currentTarget.parentElement?.parentElement?.parentElement;
              if (parent) {
                parent.className += 'showAnyway';
              }
              //set so it hides next time too
              localStorage.setItem('showAnyway', true);
            }}
          >
            Continue anyway...
          </Link>
        </Box>
      </Paper>
    </div>
  );
};

const Authenticator = (props) => {
  const [, dispatch] = useContext(StoreContext);
  useEffect(() => {
    console.log(rootUrl);
    axios
      .get(`${rootUrl}/api/pods/me`, { withCredentials: true })
      .then((result) => {
        setMe(dispatch, result.data);
        if (result.data.id) {
          axios
            .get(`${rootUrl}/api/podProfile/${result.data.id}`, {
              withCredentials: true,
            })
            .then((woman) => {
              if (woman.status === 200) {
                setMe(dispatch, woman.data);
              }
            })
            .catch((error) => {
              if (error) {
                console.log('ERROR', error);
              }
            });
        }
      });
  }, [dispatch]);
  return null;
};

const Routes = () => {
  const history = useHistory();
  const [, dispatch] = useContext(StoreContext);
  const [cookiesAccepted] = useCookie('cookiesAccepted', false);
  const [analyticsAccepted] = useCookie('gAnalyticsAccepted', true);

  useEffect(() => {
    if (cookiesAccepted && analyticsAccepted) {
      setMe(dispatch, { tracking: true });
    }
  }, [analyticsAccepted, cookiesAccepted, dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [history.location.pathname]);

  return (
    <Switch>
      <AuthRoute exact path="/" component={Login} />
      <AuthRoute path="/create-account" component={SignUp} />
      <AuthRoute path="/sign-up" component={SignUp} />
      <Route path="/about" component={About} />
      {/* <Route path="/team" component={Team} /> */}
      <PrivateRoute path="/feedback" component={Feedback} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/cookie-notice" component={CookieNotice} />
      <AuthRoute path="/forgot-password" component={ForgotPassword} />
      <AuthRoute path="/reset-password" component={ResetPassword} />
      <Route path="/frame" component={MapView} />

      <PrivateRoute path="/map" component={PodsMap} />
      <PrivateRoute path="/profile/:id" component={Profile} />
      <PrivateRoute path="/pods/:id?" component={PodsPage} />
      <PrivateRoute path="/admin-portal" component={AdminPortal} />
    </Switch>
  );
};

function Pods(props) {
  const [cookiesAccepted, setCookiesAccepted] = useCookie(
    'cookiesAccepted',
    false
  );
  const [analyticsAccepted, setAnalyticsAccepted] = useCookie(
    'gAnalyticsAccepted',
    true
  );
  const classes = useStyles();

  useEffect(() => {
    console.log('Application config set to: ', application);
    console.log(
      'Environment variable set to: ',
      process.env.REACT_APP_APPLICATION
    );
    if (cookiesAccepted && analyticsAccepted) {
      ReactGA.initialize(process.env.REACT_APP_ORCA_ANALYTICS_ID, {
        debug: process.env.NODE_ENV !== 'production',
        gaOptions: {
          siteSpeedSampleRate: 100,
          allowAdFeatures: false,
        },
      });
    }
  }, [cookiesAccepted, analyticsAccepted]);

  return (
    <div className="Pods">
      <Suspense
        fallback={
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <Store rootReducer={rootReducer}>
          <Authenticator />
          {/* <Tracker /> */}
          <ScreenCover />
          <BrowserRouter className={classes.router}>
            <Routes />
            {!cookiesAccepted && (
              <CookieBanner
                setCookiesAccepted={setCookiesAccepted}
                setAnalyticsAccepted={setAnalyticsAccepted}
                analyticsAccepted={analyticsAccepted}
              />
            )}
            {/* <Footer /> */}
          </BrowserRouter>
        </Store>
      </Suspense>
    </div>
  );
}

export default Pods;
